import FlipBookPage from "@/public_obituary/book/flip-book/FlipBookPage";
import React from "react";
import {useSelector} from "react-redux";
import {useState} from "react";
import {classNames} from "@/components/common/Helpers";

export default function BookCoverImage({ bgColor = 'gray-300'}) {
    /** @type {MutableRefObject<HTMLElement|null>} */
    const [bookWidth, setBookWidth] = useState(0);
    const [bookEvents] = useState(new EventTarget());

    const {
        obituary
    } = useSelector(state => state.obituaryReducer);

    const onBookContainerChange = (node) => {
        if (node) {
            function getNodeWidth() {
                const computedStyle = getComputedStyle(node);
                let width = node.clientWidth;
                width -= parseFloat(computedStyle.paddingLeft);
                width -= parseFloat(computedStyle.paddingRight);
                return width;
            }

            setBookWidth(getNodeWidth());

            window.addEventListener('resize', () => {
                bookEvents.dispatchEvent(new CustomEvent('pageResize', {detail: getNodeWidth()}));
            });
        } else {
            setBookWidth(0);
        }
    };

    if (!obituary) return null;

    return (
        <div className={classNames(`flex w-full book-preview bg-${bgColor} p-5 align-items-center justify-center relative`)}>
            <div className="flex-1 content-center shadow-lg shadow-black/35" ref={onBookContainerChange}>
                <FlipBookPage
                    page={1}
                    initialLoaded={true}
                    events={bookEvents}
                    obituaryUuid={obituary?.id}
                    initialPageWidth={{current: bookWidth}}
                />
            </div>

            <style dangerouslySetInnerHTML={{
                __html: `
                      .book-preview .page-1 {
                        border: none;
                        border-radius: 3px;
                        border-top-right-radius: 2px;
                        border-bottom-right-radius: 1px;
                        overflow: hidden;
                        padding: 0 !important;
                      }
                    `
            }}></style>
        </div>
    );
}
